<script setup>
import { RouterView } from 'vue-router'
import { mapStores } from 'pinia'

import { useWorkerStore } from '@/stores'
import ScanTag from '@/modals/ScanTag.vue'
</script>

<script>
import { auth } from '@/firebase' // Import the auth object from your firebase.js file

import utils from '@/utils.js'
import liffapi from '@/liffapi'

export default {
  name: 'LoginApp',

  props: {
    redirectUrl: {
      type: String,
      default: null,
    },
    application: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    baseUrl: import.meta.env.VITE_API_SERVER || import.meta.env.BASE_URL,
    form: {
      idToken: null,
      redirect: null,
    },
    isLoading: false,
    notifications: [],
    showScanner: false,
    title: 'Dashboard Login',
    user: null,
    version: __COMMIT_HASH__,
  }),

  computed: {
    ...mapStores(useWorkerStore),

    isMobile() {
      return utils.isMobile()
    },
  },

  async created() {
    liffapi
      .getCurrentUser()
      .then((user) => {
        if (user) {
          this.worker.setWorker(user)
        }
      })
      .catch(() => {})
  },

  methods: {
    redirectToDestination(form) {
      let url = ''
      if (form.application) {
        // set the base url
        switch (form.application) {
          case 'dashboard':
            url = 'dashboard'
            break
          case 'console':
            url = ''
            break
          case 'toweltracker':
            url = 'toweltracker/'
            break
          case 'settings':
            url = 'settings/'
            break
          case 'manage':
            url = 'manage/'
            break
        }
      }
      // it's just a path at dashboard
      if (form.path) {
        url += '#/' + form.path
        return url
      }
      return '#/'
    },

    authToCookie(authResult, redirectForm) {
      if (redirectForm.path) {
        // convert the form to a full url
      }
      let url = this.redirectToDestination(redirectForm)
      if (authResult.user) {
        this.isLoading = true
        authResult.user.getIdToken().then((idToken) => {
          this.form.idToken = idToken
          this.form.redirect = url
          this.$nextTick().then(() => {
            this.$refs.redirectForm.submit()
          })
        })
      } else {
        // self.renderLoggedOut();
      }
      return false
    },

    doAuth(token) {
      // if token auth else redirect to login?
      this.isLoading = true
      auth
        .signInWithCustomToken(token)
        .then((userCredential) => {
          // Signed in
          let user = userCredential.user
          user.getIdToken().then((idToken) => {
            this.form.idToken = idToken
            this.form.redirect = '#/' // where to go from onboarding?
            this.$nextTick().then(() => {
              this.$refs.redirectForm.submit()
            })
          })
        })
        .catch((error) => {
          console.error(error)
          console.debug(error.response)
          let msg = error.response && error.response.data.detail.error
          utils.toast(msg, 'danger')
        })
        .finally(() => (this.isLoading = false))
    },

    onDecode(content) {
      if ((content?.length ?? 1) < 40) {
        let msg = 'Bad scan'
        utils.toast(msg, 'danger')
        return
      }

      this.isLoading = true
      liffapi.users
        .authByToken(content)
        .then((response) => {
          utils.toast(response.message, 'success')
          this.doAuth(response.customToken)
        })
        .catch((error) => {
          let msg = error.response && error.response.data.detail.error
          console.error(msg)
          utils.toast(msg, 'danger')
        })
        .finally(() => (this.isLoading = false))
    },

    ping() {
      return liffapi.ping().then((result) => result.status)
    },

    scanOff() {
      this.showScanner = false
    },
  },
}
</script>

<template>
  <div class="app-content">
    <section id="app" class="hero is-fullheight">
      <div class="hero-body is-justify-content-center">
        <div class="is-flex">
          <template v-if="!showScanner">
            <RouterView
              class=""
              style=""
              @authenticated="authToCookie"
              @openscan="showScanner = true"
            />
          </template>

          <template v-else>
            <ScanTag :camera="camera" login @scan="onDecode">
              <a class="close-icon" @click="scanOff">
                <o-icon icon="close" size="is-medium" />
              </a>
            </ScanTag>
          </template>
        </div>
      </div>
    </section>

    <div class="floating-action">
      <o-button
        size="is-large"
        icon-left="qrcode-scan"
        @click="showScanner = true"
      />
    </div>

    <form ref="redirectForm" class="is-hidden" method="post" action="/callback">
      <input name="idToken" :value="form.idToken" />
      <input name="redirect" :value="form.redirect" />
    </form>

    <o-loading is-full-page :active="isLoading"></o-loading>
  </div>
</template>

<style>
@import '@mdi/font/css/materialdesignicons.css';

menu {
  width: 16rem;
  max-width: 16rem;
  height: 100vh;
  margin: 0;

  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100vw;
  }
}

.menu-hamburger {
  top: 0;
  left: 0;
  display: none;
  z-index: 1;

  @media (max-width: 768px) {
    position: fixed;
    display: inline;
    max-width: 100vw;
    width: 100vw;
  }
}

.app-content {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  @media (max-width: 768px) {
  }
}

.firebaseui-auth-container {
  width: 362px;
  max-width: 362px;
  min-height: 20rem;
}

.firebaseui-card-header {
  background: rgb(1, 45, 89);
  margin: 0px auto;
  padding-top: 1.5rem;
}

.floating-action {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
}

.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 100;
  color: white;
}
</style>
